import React from 'react';
import Containers from 'components/article/Containers';
import DownArrow from 'components/DownArrow';

export default function(props) {

  const article = props.article || {}

  return <>
    <div className="Article-cover" style={{color: article.textColor || '#000000'}}>
      <div className="Article-title">{article.title}</div>
      { article.subtitle &&
        <div className="Article-subtitle">{article.subtitle}</div>
      }
      { article.attribution &&
        <div className="Article-attribution">{article.attribution}</div>
      }
      <DownArrow style={{position: 'absolute', left: 0, bottom: '20px'}} />
    </div>

    <div className="Article-cover-content">
      { article.content &&
        <div className="Article-wrapper">
          <div className="Article-content" dangerouslySetInnerHTML={{ __html: article.content }}></div>
        </div>
      }
      { article.moduleContainers &&
        <Containers {...{article}}/>
      }
      <div className="Article-spacer"></div>
    </div>
  </>
}