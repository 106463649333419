import React from 'react';
import MediaItem from 'components/media/MediaItem';
import Containers from 'components/article/Containers';

export default function(props) {

  const article = props.article || {}

  return <>
    <div className="Article-wrapper">
      <div className="Article-row">
        <div className="Article-col">
          <div className="Article-title">{article.title}</div>
          { article.subtitle &&
            <div className="Article-subtitle">{article.subtitle}</div>
          }
          <div className="Article-attribution">{article.attribution}</div>
          <MediaItem data={article.featuredImage} style={{marginTop: '50px'}}/>
        </div>
        <div className="Article-col">
          <div className="Article-content" dangerouslySetInnerHTML={{ __html: article.content }}></div>
        </div>
      </div>
    </div>

    { article.moduleContainers &&
      <Containers {...{article}}/>
    }
    <div className="Article-spacer"></div>
  </>
}