import React, { useState, useEffect } from 'react';
import ResourceItem from 'components/article/ResourceItem';

export default ({data}) => {
  const [listItems, setListItems] = useState([]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setListItems(data)
    }
  }, [data])

  return <div className="ArticleResources">
    <div className="ArticleResources-content">
      <h1>Resources</h1>
      <div key={'spacer'} className="ArticleResource-spacer-first" />
      { listItems.map((item, index) =>
        <div key={index}>
          <ResourceItem data={item} />
          <div className="ArticleResource-spacer" />
        </div>
      )}
    </div>
  </div>
}