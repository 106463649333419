import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeadphones, faFilm, faFilePdf, faFile, faImage } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';

export default ({data}) => {
  const [formattedDate, setFormattedDate] = useState();
  const [linkUrl, setLinkUrl] = useState();
  const [fileIcon, setFileIcon] = useState(faFile);

  const selectIcon = fileType => {
    if (/pdf/i.test(fileType)){
      setFileIcon(faFilePdf);
    }
    else if (/image/i.test(fileType)){
      setFileIcon(faImage);
    }
    else if (/audio/i.test(fileType)){
      setFileIcon(faHeadphones);
    }
    else if (/video/i.test(fileType)){
      setFileIcon(faFilm);
    }
    else {
      setFileIcon(faFile);
    }
  }

  useEffect(() => {
    if (!data) return;
    const {media, fileType, date, fileUrl} = data;
    setLinkUrl(fileUrl);

    if (date) {
      const formatted = moment.unix(date._seconds).format('MMMM D, YYYY');
      setFormattedDate(formatted);
    }
    else if (media) {
      if (Array.isArray(media.outputs)) {
        const selected = media.outputs.find(
          item => /mp4 high|mp3/.test(item.label)
        );
        setLinkUrl(selected.url);
      }
      else {
        setLinkUrl(media.imageUrl);
      }
    }

    // Set icon
    if (media) {
      selectIcon(media.mediaType);
    }
    else {
      selectIcon(fileType);
    }
  }, [data]);

  return <a href={linkUrl} target="_blank" rel="noopener noreferrer" className="ArticleResource-item">
    <div className="ArticleResource-icon">
      <FontAwesomeIcon icon={fileIcon} />
    </div>
    <div className="ArticleResource-name">{data.name}</div>
    <div className="ArticleResource-description">{data.description}</div>
    <div className="ArticleResource-date">{formattedDate}</div>
  </a>
}
