import React, { useEffect, useState, useRef } from 'react';
import SectionLine from 'components/SectionLine';
import AboutSection from 'components/AboutSection';
import DownArrow from 'components/DownArrow';
import AudioPlayerMini from 'components/media/AudioPlayerMini';
import { Link } from 'react-router-dom';
import { getHomepage } from 'shared/firedatabase';
import { navbarHeight } from 'css/_variables.scss';

const scrollSpeed = 0.33; // Number from 0 to 1
const audioSource = [{
  url: 'https://shout.kainoco.com:8003/;?type=http&nocache=5'
}];

export default props => {
  const [featuredArticle, setFeaturedArticle] = useState();
  const [aboutData, setAboutData] = useState();
  const [sections, setSections] = useState();
  const [scrollY, setScrollY] = useState(0);
  const [landingHeight, setLandingHeight] = useState(0);
  const [linesTop, setLinesTop] = useState(0);
  const [sectionHeight, setSectionHeight] = useState(0);
  const [aboutHeight, setAboutHeight] = useState(0);
  const [featuredOpacity, setFeaturedOpacity] = useState(1);
  const numberOfLines = useRef(0);
  const startupDuration = 2000;
  const minTop = parseInt(navbarHeight);
  const maxTop = useRef(0);

  const getMinAboutHeight = () => {
    return window.innerHeight - 150 - (numberOfLines.current * 8);
  }

  useEffect(() => {
    if (aboutHeight > 200) {
      setLandingHeight(prev => prev + aboutHeight);
    }
  }, [aboutHeight])

  useEffect(() => {
    const sectionHeight = window.innerWidth * 0.5;
    const halfHeight = sectionHeight / 2;
    const homepagePromise = getHomepage()
    const sections = [];
  
    const handleScroll = () => {
      const scrollY = window.scrollY * scrollSpeed;
      let ratio;
      setScrollY(scrollY);

      if (scrollY <= 0) {
        setLinesTop(maxTop.current);
        ratio = 1;
      }
      else if (scrollY > 0 && scrollY < halfHeight) {
        ratio = 1 - (scrollY / halfHeight);
        const top = (ratio * (maxTop.current - minTop)) + minTop;
        setLinesTop(top);
      }
      else if (scrollY > halfHeight) {
        ratio = 0;
        setLinesTop(minTop);
      }
      setFeaturedOpacity(ratio);
    }

    const initSections = async () => {
      const resp = await homepagePromise
      const articles = resp.sections || [];
      let featuredArticle
      articles.forEach((article, index) => {
        if (index === 0) {
          featuredArticle = article;
        }
        else {
          sections[index - 1] = article;
        }
      });
      setSections([...sections]);
      window.addEventListener('scroll', handleScroll);

      const height = (sectionHeight / 2) * (articles.length + 1);
      setLandingHeight(prev => prev + (height / scrollSpeed));
      setFeaturedArticle(featuredArticle);
      setAboutData({
        minHeight: getMinAboutHeight(),
        ...resp.about
      });

      homepagePromise.then(data => {
        let sections = data.sections || [];
        if (sections.length && sections[0].featuredImage) {
          props.setBackground(sections[0].featuredImage.imageUrl);
        }
        if (data.navColor) {
          props.setNavColor(data.navColor);
        }
      });
    };

    homepagePromise.then(resp => {
      numberOfLines.current = resp.sections.length;
      maxTop.current = (window.innerHeight - numberOfLines.current * 8) / 2;
      setLinesTop(maxTop.current);
      [...Array(numberOfLines.current).keys()].forEach(index => {
        sections.push({active: false});
      });
      setSections(sections);
      setTimeout(initSections, startupDuration);

      // Preload featured image
      const image = window.document.createElement('img');
      image.src = resp.sections[0].featuredImage.imageUrl;
    });

    setSectionHeight(sectionHeight);
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return !sections ? null : <>
    <div className="Landing" style={{height: Math.round(landingHeight)+'px', position: 'relative'}}>
      { featuredArticle &&
        <div className="SectionPreview-body SectionPreview-title" style={{
          position: 'fixed',
          marginTop: 0,
          zIndex: 0,
          top: Math.round(maxTop.current - 100)+'px',
          textDecoration: 'none'
        }}>
          <Link to={'/article/' + featuredArticle.idArticle}
            style={{ color: featuredArticle.textColor || '#000000', opacity: featuredOpacity }}
          >
            {featuredArticle.title}
          </Link>
        </div>
      }
      <div className="Landing-lines" style={{top: Math.round(linesTop)+'px'}}>
        { sections.map((item, index) => (
          <SectionLine scrollY={item.active ? scrollY : 0} key={index} index={index} data={item} height={sectionHeight} />
        )) }
      </div>
      { featuredArticle &&
        <DownArrow style={{position: 'fixed', left: 0, bottom: '20px'}} />
      }
      { aboutData &&
        <AboutSection onHeightChange={setAboutHeight} data={aboutData}/>
      }
    </div>
    <AudioPlayerMini sources={audioSource} visible={true} />
  </>;
};