import React, { useEffect, useState } from 'react';
import SectionPreview from 'components/SectionPreview';

const minHeight = 8;

const SectionLine = props => {

  const [lineTop, setLineTop] = useState();
  const [finishedStartup, setFinishedStartup] = useState(false);

  const startupAnimation = (index) => {
    //const top = Math.pow(index, 2.5) + minHeight;
    const top = window.innerHeight;
    setLineTop(top);

    setTimeout(() => {
      setLineTop(0);
    }, (200 * index) + 10);
  };


  useEffect(() => {
    const {index} = props;
    if (!finishedStartup) {
      startupAnimation(index);
      setFinishedStartup(true);
    }
  }, [props, finishedStartup])

  return (
    <div>
      <div className="SectionLine" style={{top: Math.round(lineTop)+'px'}} />
      { props.data.active &&
        <SectionPreview {...props} />
      }
    </div>
  );
};

export default SectionLine;