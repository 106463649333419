import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getRecentArticles, getResources } from 'shared/firedatabase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import 'css/NavBar.scss';
import logo from 'images/_ships.svg';
import closeImage from 'images/close-sans.svg';
import CategorySections from 'components/CategorySections';
import ResourceItem from 'components/article/ResourceItem';
import FlyoutSearch from 'components/FlyoutSearch';
import AboutSection from 'components/AboutSection';

export default function NavBar(props) {
  const [breadcrumb, setBreadcrumb] = useState('Library');
  const [aboutVisible, setAboutVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [libraryVisible, setLibraryVisible] = useState(false);
  const [itemList, setItemList] = useState([]);
  const currentCategory = useRef();

  const toggleAbout = () => {
    setAboutVisible(!aboutVisible);
    setLibraryVisible(false);
  };

  const toggleLibrary = () => {
    setLibraryVisible(!libraryVisible);
    setAboutVisible(false)
    if (libraryVisible) {
      setBreadcrumb('Library')
    }
  };

  const toggleSearch = () => {
    setSearchVisible(!searchVisible);
    setLibraryVisible(false);
  };
  
  const changeCategory = (category, breadcrumb) => {
    if (breadcrumb) {
      setBreadcrumb(breadcrumb)
    }
    if (category) {
      currentCategory.current = category;
      filterArticles(category);
    }
  };

  async function filterArticles(category) {
    if (category.type === 'resource') {
      const itemList = await getResources({category: category.idCategory});
      if (category === currentCategory.current) {
        setItemList(itemList.resources);
      }
    }
    else {
      const itemList = await getRecentArticles({category: category.idCategory});
      if (category === currentCategory.current) {
        setItemList(itemList.articles);
      }
    }
  }

  const closeFlyout = () => {
    setLibraryVisible(false);
    setAboutVisible(false);
  }

  useEffect(() => {
    getRecentArticles().then(data => setItemList(data.articles));
  }, [])

  return <>
    <div className="NavBarMobile">
      <div className="NavBarMobile-menuItem" onClick={toggleAbout} style={{fontWeight: aboutVisible ? 700 : 300}}>
        About
      </div>
      {/*
      <div className="NavBarMobile-menuItem" onClick={toggleLibrary} style={{fontWeight: libraryVisible ? 700 : 300}}>
        {breadcrumb}
      </div>
      */}
      <div className="NavBarMobile-logo">
        <Link to={'/'} >
          <img src={logo} alt="Logo" height={40} />
        </Link>
      </div>
    </div>

    { libraryVisible && <>
      <div className="NavBarMobile-library">
        <div className="NavBarMobile-searchIcon" onClick={toggleSearch}>
          <FontAwesomeIcon icon={faSearch} size="lg" color="#c8c9cb"/>
        </div>
        <div className="FlyoutSection-name NavBarMobile-header">
          Collections
        </div>
        <CategorySections onChange={changeCategory} />
      </div>
      <div className="NavBarMobile-results">
        { itemList.map(item => item.idResource ?
          <ResourceItem data={item} key={item.idResource} /> :
          <Result data={item} key={item.idArticle} onClose={closeFlyout} />
        )}
      </div>
    </>}

    { aboutVisible &&
      <div className="NavBarMobile-about">
        <AboutSection />
        <img className="Flyout-close" src={closeImage} alt="close" onClick={closeFlyout}/>
      </div>
    }

    { searchVisible && 
      <div className="NavBarMobile-search">
        <FlyoutSearch onClose={() => setSearchVisible(false)}/>
      </div>
    }

  </>
}

export function Result(props) {
  const data = props.data || {};

  return <div className="FlyoutResult">
    <Link to={'/article/' + data.idArticle} onClick={props.onClose}>
        <img className="FlyoutResult-image" src={data.featuredImage.imageUrl} alt={data.title}/>
        <div className="FlyoutResult-title">{data.title}</div>
        <div className="FlyoutResult-subtitle">{data.subtitle}</div>
    </Link>
  </div>
}