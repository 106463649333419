// https://css-tricks.com/making-an-audio-waveform-visualizer-with-vanilla-javascript/
import React, { useEffect, useState } from 'react'; 
import { Scrubber } from 'react-scrubber';
import 'react-scrubber/lib/scrubber.css'

export default props => {
  const sampleSize = Math.round(((0.8 * window.innerWidth) - 200) / 6);
  const src = props.src;
  const mediaProgress = props.progress;

  const [activeData , setActiveData] = useState([]);
  const [targetProgress , setTargetProgress] = useState();
  const [progress , setProgress] = useState();
  const filteredData = useWaveformData(src, sampleSize);

  const handleScrubEnd = (value) => {
    setTargetProgress(value);
    props.onChange(value);
  }

  useEffect(() => {
    const updateActive = ratio => {
      const total = sampleSize;
      const cutoff = Math.round(total * ratio);
      const data = [];
      for (let i = 0; i < total; i++) {
        data.push(i < cutoff);
      }
      setActiveData(data);
    }

    if (targetProgress) {
      setProgress(targetProgress);
      updateActive(targetProgress);

      // Don't update scrubber until media progress is updated to be within range
      if (mediaProgress > targetProgress - 0.01 && mediaProgress < targetProgress + 0.01) {
        setTargetProgress(undefined);
      }
    }
    else {
      setProgress(mediaProgress);
      updateActive(mediaProgress);
    }
  }, [targetProgress, mediaProgress, sampleSize]);

  return <div className="AudioWaveform">
    <div className="AudioWaveform-bars">
      { filteredData.map((value, index) => 
        <div className={`AudioWaveform-bar${activeData[index] ? '--active' : ''}`} key={index} style={{height: (value * 100) + '%'}} />
      )}
    </div>
    <div className="scrubber-container">
      <Scrubber
        min={0}
        max={1}
        value={progress}
        onScrubStart={setTargetProgress}
        onScrubChange={setTargetProgress}
        onScrubEnd={handleScrubEnd}
      />
    </div>
  </div>
}

const useWaveformData = (src, samples) => {
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (src) (async () => {
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      const audioContext = new AudioContext();
      const response = await fetch(src)
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await new Promise((resolve, reject) => {
        // Safari doesn't return a Promise, so create one
        audioContext.decodeAudioData(arrayBuffer, resolve, reject)
      });
      const filteredData = filterData(audioBuffer, samples);
      setFilteredData(filteredData);
    })();
  }, [src, samples])

  return filteredData;
};

const filterData = (audioBuffer, samples) => {
  const rawData = audioBuffer.getChannelData(0); // We only need to work with one channel of data
  const blockSize = Math.floor(rawData.length / samples); // the number of samples in each subdivision
  const filteredData = [];
  for (let i = 0; i < samples; i++) {
    let blockStart = blockSize * i; // the location of the first sample in the block
    let sum = 0;
    for (let j = 0; j < blockSize; j++) {
      sum = sum + Math.abs(rawData[blockStart + j]) // find the sum of all the samples in the block
    }
    filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
  }
  return normalizeData(filteredData);
}

const normalizeData = filteredData => {
  const multiplier = Math.pow(Math.max(...filteredData), -1);
  return filteredData.map(n => n * multiplier);
}