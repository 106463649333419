const apiUrl = 'https://us-central1-breathe-30cae.cloudfunctions.net/api';

async function apiRequest(endpoint) {
  const response = await fetch(apiUrl + endpoint);
  const data = await response.json();
  return data;
}

export function getHomepage() {
  return apiRequest('/homepage');
}

export function getAbout() {
  return apiRequest('/about');
}

export function getArticle({id}) {
  return apiRequest('/article_id/' + id);
}

export function getPreview({id}) {
  return apiRequest('/preview/' + id);
}

export function getMedia({id}) {
  return apiRequest('/media/' + id);
}

export function getSearchableArticles() {
  return apiRequest('/articles/searchable');
}

export function getRecentArticles(options = {}) {
  const {category} = options;
  const endpoint = '/articles/' + (category ? `category/${category}` : 'recent');
  return apiRequest(endpoint);
}

export function getResources(options = {}) {
  const {category} = options;
  const endpoint = `/resources/category/${category}`;
  return apiRequest(endpoint);
}

export function getCategories() {
  return apiRequest('/categories/menu');
}


