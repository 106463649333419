import React from 'react';
import { useDynamicAudioBars } from 'shared/hooks';

export default (props) => {
  const {mediaRef, visible} = props;
  const {bars, samples} = useDynamicAudioBars({mediaRef, visible});

  const handleClick = () => {
    props.onClick();
  };

  return <div className="AudioBars" onClick={handleClick}>
    { samples.map((val, index) => 
      <div className="AudioBars-bar" key={index} style={{height: `${10 + Math.round(bars[index] * 50)}px`}} />
    ) }
  </div>
}
