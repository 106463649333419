import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

export default () => {

  return <div className="Article-footer">
    <div className="Article-wrapper">
      <div className="Article-footer__divider"/>
      <div className="Article-footer__share">
        <span>share</span>
        <FontAwesomeIcon icon={faInstagram} />
        <FontAwesomeIcon icon={faTwitter} />
        <FontAwesomeIcon icon={faEnvelope} />
      </div>

    </div>
  </div>

};