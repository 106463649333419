import React, { useEffect, useRef, useState }from 'react';
import AudioMute from 'components/media/AudioMute';
import pauseIcon from 'images/pause-white.svg';
import playIcon from 'images/play-white.svg';

export default ({visible, children, sources}) => {
  const [buttonIcon, setButtonIcon] = useState(pauseIcon);
  const mediaRef = useRef();
  const mediaSources = sources || [];

  const handlePlayerState = () => {
    const media = mediaRef.current;
    if (media.readyState >= 2) {
      // Ready
    }
  };

  const togglePlay = () => {
    if (mediaRef.current.paused) {
      mediaRef.current.play();
    }
    else {
      mediaRef.current.pause();
    }
  }

  const toggleMute = () => {
    mediaRef.current.muted = !mediaRef.current.muted;
  }

  useEffect(() => {
    const media = mediaRef.current;
    if (visible) {
      media.currentTime = 0;
      media.play();
    }
    else {
      media.pause();
    }
  }, [visible]);

  return <div className="AudioPlayerMini">
    <audio
      ref={mediaRef}
      className="MediaModal-audio"
      crossOrigin={'anonymous'}
      onLoadedData={handlePlayerState}
      onPlay={() => setButtonIcon(pauseIcon)}
      onPause={() => setButtonIcon(playIcon)}
    >
      { mediaSources.map(item => 
        <source key={item.label} src={item.url.replace('http:', 'https:')} type={`audio/${item.label}`} crossOrigin="anonymous"/>
      )}
    </audio>
    <div className="AudioPlayer">
      <div className="AudioPlayIcon" style={{backgroundImage: `url(${buttonIcon})`}} onClick={togglePlay}/>
      <AudioMute mediaRef={mediaRef} visible={visible} onClick={toggleMute}/>
    </div>
  </div>
}