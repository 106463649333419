import React from 'react';

export default ({ embed }) => {
  return <div className="Article-embed">
    <div className="Article-embed__wrapper"
      dangerouslySetInnerHTML={{
        __html: embed
      }}
    />
  </div>
}