import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'css/SectionPreview.scss';

const SectionPreview = props => {
  const [height, setHeight] = useState('auto');
  const [data, setData] = useState({});
  const [textColor, setTextColor] = useState('#000000');
  const [backgroundImage, setBackgroundImage] = useState('none');

  useEffect(() => {
    const {height, scrollY, index, data} = props;

    if (typeof height === 'number') {
      const halfHeight = height / 2;
      const startY = index * halfHeight;
      const midY = startY + halfHeight;
      const endY = startY + height;

      if (scrollY > startY && scrollY < midY) {
        const delta = midY - scrollY;
        const ratio = 1 - (delta / halfHeight);
        setHeight(`${height * ratio}px`);
      }
      else if (scrollY >= midY && scrollY < endY) {
        const delta = endY - scrollY;
        const ratio = delta / halfHeight;
        setHeight(`${height * ratio}px`);
      }
      else {
        setHeight(0);
      }
    }

    if (data.textColor) {
      setTextColor(data.textColor);
    }

    if (data.featuredImage) {
      setBackgroundImage(`url(${data.featuredImage.imageUrl})`);
    }

    setData(data);
  }, [props])

  return (
    <div className="SectionPreview">
      <div className="SectionPreview-body" style={{height, backgroundImage, ...props.style}}>
        <Link className="SectionPreview-link" to={'/article/' + data.idArticle}>
          <div className="SectionPreview-title" style={{color: textColor}}>{data.title}</div>
          <div className="SectionPreview-subtitle" style={{color: textColor}}>{data.subtitle}</div>
        </Link>
      </div>
    </div>
  );
}

export default SectionPreview;