import React, { useEffect, useRef }from 'react';
import { Link } from 'react-router-dom';
import 'css/Flyout.scss'
import closeImage from 'images/close-sans.svg';
import NavBarLinks from 'components/NavBarLinks';

// Algolia search setup
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, connectSearchBox, connectHits, connectStats } from 'react-instantsearch-dom';
const searchClient = algoliasearch('I2FJJ75XWQ', '14c98a50f752ae6799d635d4aa9bd633');

export default ({onClose, onMenuChange}) => {
  const inputRef = useRef();
  let searchState = {};

  const onSearchChange = state => {
    Object.assign(searchState, state);
  }

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const CustomSearchBox = connectSearchBox(SearchBox);
  const CustomHits = connectHits(Hits);
  const CustomStats = connectStats(Stats);

  return <div className="Flyout Flyout-search">
      <InstantSearch indexName="articles" searchClient={searchClient} onSearchStateChange={onSearchChange}>
        <div className="Flyout-search-count">
          <CustomStats {...{searchState}} />
        </div>
        <CustomSearchBox {...{onSearchChange, inputRef}} />
        <CustomHits {...{onClose, searchState}} />
      </InstantSearch>
      <img className="Flyout-close" src={closeImage} alt="close" onClick={onClose}/>
      <NavBarLinks value="search" onChange={onMenuChange} />
    </div>
}

const SearchBox = ({ currentRefinement, refine, inputRef }) => (
  <form noValidate action="" role="search">
    <input
      ref={inputRef}
      className="Flyout-input"
      placeholder="Search Collection"
      value={currentRefinement}
      onChange={ev => refine(ev.currentTarget.value)}
    />
  </form>
);

const Stats = ({nbHits, searchState}) => (
  <span>
    {searchState.query &&
      <span>Showing: {nbHits} Result{nbHits === 1 ? '' : 's'}</span>
    }
  </span>
);

const Hits = ({ hits, onClose, searchState }) => (
  <div className="Flyout-search-results">
    {searchState.query && hits.map(data => (
      <div key={data.objectID} className="Flyout-search-result">
        <div className="FlyoutResult">
          <Link to={'/article/' + data.objectID} onClick={onClose}>
            <img className="FlyoutResult-image" src={data.imageUrl} alt={data.title}/>
            <div className="FlyoutResult-title">{data.title}</div>
          </Link>
        </div>
      </div>
    ))}
  </div>
);