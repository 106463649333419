import React, { useState } from 'react';
import { createPortal } from 'react-dom'
import Fade from 'components/Fade';
import closeImage from 'images/close-sans-white.svg';
import 'css/ImageModal.scss'
import nextIcon from 'images/arrow-white.svg';

export default props => {
  const [modalVisible, setModalVisible] = useState(false);
  const data = props.data || {};
  const modules = props.modules;
  const { aspectRatio } = props;
  let paddingTop;

  function handleClick(ev) {
    setModalVisible(true);
  }

  function handleClose() {
    setModalVisible(false);
  }

  if ( typeof aspectRatio === 'number' ) {
    if ( aspectRatio <= 0 ) {
      paddingTop = '0';
    } else if ( aspectRatio > 1) {
      paddingTop = '100%';
    } else {
      paddingTop = `${(1/aspectRatio) * 100}%`;
    }
  }

  return <>
    { paddingTop ?
      <div className="ImageModal-preview" style={{backgroundImage: `url(${data.imageUrl}`, paddingTop}}  onClick={handleClick} /> :
      <img className="ImageModal-preview" src={data.imageUrl} alt="preview" onClick={handleClick} />
    }
    { data.caption &&
      <div className="ImageModal-caption">{data.caption}</div>
    }
    { createPortal(
      <Fade visible={modalVisible}>
        <div className="ImageModal">
          <div className="ImageModal-image" style={{backgroundImage: `url(${data.imageUrl})`}} />
          <img className="ImageModal-close" src={closeImage} alt="Close modal" onClick={handleClose}/>
          <div className="ImageModal-caption--bottom">{data.caption}</div>
          {modules && <>
            <div className="ImageModal-nav Article-carousel-nav">
              <div className="Article-carousel-back" style={{backgroundImage: `url(${nextIcon})`}}></div>
              <div className="Article-carousel-next" style={{backgroundImage: `url(${nextIcon})`}}></div>
            </div>
          </>}
        </div>
      </Fade>
    , document.body)}
  </>
}