import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getArticle, getPreview } from "shared/firedatabase";
import ArticleText from "components/article/Text";
import Article2Col from "components/article/TwoColumn";
import ArticleCover from "components/article/Cover";
import Footer from "components/article/Footer";
import Resources from "components/article/Resources";
import Author from 'components/article/Author';
import 'css/Article.scss';

export default function Article(props) {
  const {idArticle} = useParams();
  const [article, setArticle] = useState({});
  const {setBackground} = props;

  useEffect(() => {

    (async () => {
      let id = idArticle;
      let resp;
      if (id.includes('preview:')) {
        id = id.split(':').pop();
        resp = await getPreview({id});
      }
      else {
        resp = await getArticle({id});
      }

      const {article} = resp;
      const {layout} = article;
      setArticle(article);
      console.log(article);

      if (setBackground) {
        setBackground(layout === 'backgroundCover' ? article.featuredImage.imageUrl : null);
      }
    })();

  }, [idArticle, setBackground]);

  return <div className="Article">
    { article.layout !== 'backgroundCover' &&
      <div className="SectionLine LoadingBar"></div>
    }
    { article.layout === 'oneColumn' &&
      <ArticleText article={article} />
    }
    { article.layout === 'twoColumn' &&
      <Article2Col article={article} />
    }
    { article.layout === 'backgroundCover' &&
      <ArticleCover article={article} />
    }
    <Footer />
    { article.resources &&
      <Resources data={article.resources} />
    }
    { article.author && 
      <Author data={article.author} />
    }
  </div>
}