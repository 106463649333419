import React, { useEffect, useRef, useState } from 'react';
import AudioMute from 'components/media/AudioMute';
import { Scrubber } from 'react-scrubber';
import pauseIcon from 'images/pause-white.svg';
import playIcon from 'images/play-white.svg';

export default ({visible, sources, aspectRatio}) => {
  const isMobile = window.innerWidth <= 768;
  const [progress, setProgress] = useState(0);
  const [mediaProgress, setMediaProgress] = useState();
  const [targetProgress, setTargetProgress] = useState();
  const [timestamp, setTimestamp] = useState('0:00');
  const [buttonIcon, setButtonIcon] = useState(pauseIcon);
  const mediaRef = useRef();
  const mediaSources = [];

  // Prepare sources
  (sources || []).forEach(source => {
    let include = true;
    if (source.label === 'mp4 low') {
      include = isMobile
    }
    if (source.label === 'mp4 high') {
      include = !isMobile
    }
    if (include) {
      mediaSources.push({
        label: source.label,
        url: source.url.replace('http:', 'https:'),
        type: `video/${source.format === 'mpeg4' ? 'mp4' : source.format}`
      });
    }
  });

  // Adjust dimensions to fit within window
  let maxWidth = window.innerWidth;
  const maxHeight = window.innerHeight;
  const height = maxWidth / aspectRatio;
  if (height > maxHeight) {
    maxWidth = maxHeight * aspectRatio;
  }
  const width = maxWidth + 'px';

  const handleTimeUpdate = ev => {
    const currentTime = ev.target.currentTime;
    const progress = currentTime / ev.target.duration;
    const min = Math.round(currentTime / 60)
    const sec = Math.round(currentTime % 60)
    setTimestamp(`${min}:${sec < 10 ? '0' : ''}${sec}`);
    setMediaProgress(progress);
  };

  const handleScrubEnd = value => {
    setTargetProgress(value);
    mediaRef.current.currentTime = mediaRef.current.duration * value;
  };

  const togglePlay = () => {
    if (mediaRef.current.paused) {
      mediaRef.current.play();
    }
    else {
      mediaRef.current.pause();
    }
  }

  const toggleMute = () => {
    mediaRef.current.muted = !mediaRef.current.muted;
  }

  useEffect(() => {
    const media = mediaRef.current;
    if (visible) {
      media.currentTime = 0;
      media.play();
    }
    else {
      media.pause();
    }
  }, [visible]);

  useEffect(() => {
    if (targetProgress) {
      setProgress(targetProgress);

      // Don't update scrubber until media progress is updated to be within range
      if (mediaProgress > targetProgress - 0.01 && mediaProgress < targetProgress + 0.01) {
        setTargetProgress(undefined);
      }
    }
    else {
      setProgress(mediaProgress);
    }
  }, [targetProgress, mediaProgress])

  return <div className="VideoPlayer">
    <div className="VideoPlayer-container" style={{paddingTop: `${(1/aspectRatio) * 100}%`, width}}>
      <video className="VideoPlayer-video"
        ref={mediaRef}
        crossOrigin={'anonymous'}
        onTimeUpdate={handleTimeUpdate}
        onPlay={() => setButtonIcon(pauseIcon)}
        onPause={() => setButtonIcon(playIcon)}
      >
        { mediaSources.map(item => 
          <source key={item.label} src={item.url} type={item.type} crossOrigin="anonymous" />
        )}
      </video>
      <div className="VideoPlayer-controls-container">
        <div className="VideoPlayer-controls">
          <div className="AudioPlayIcon" style={{backgroundImage: `url(${buttonIcon})`}} onClick={togglePlay}/>
          <div className="scrubber-container">
            <Scrubber
              min={0}
              max={1}
              value={progress}
              onScrubStart={setTargetProgress}
              onScrubChange={setTargetProgress}
              onScrubEnd={handleScrubEnd}
            />
          </div>
          <div className="MediaTimestamp">{timestamp}</div>
          <AudioMute mediaRef={mediaRef} visible={visible} onClick={toggleMute}/>
        </div>
      </div>
    </div>
  </div>
}