import React, { useState, useEffect } from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import NavBar from 'components/NavBar';
import NavBarMobile from 'components/NavBarMobile';
import Homepage from 'pages/Homepage';
import HomepageMobile from 'pages/HomepageMobile';
import Article from 'pages/Article';
import Embed from 'pages/Embed';
import { useIsMobile } from 'shared/hooks';
import Fade from 'components/Fade'

export default () => {
  const [background, setBackground] = useState();
  const [navColor, setNavColor] = useState();
  const [isEmbed, setIsEmbed] = useState(false);
  const {pathname} = useLocation();
  const [articleClassName, setArticleClassName] = useState('');
  const isMobile = useIsMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
    setBackground(null);
    setNavColor(null);
    setIsEmbed(/^\/embed\//.test(pathname));

    if (/^\/article\//.test(pathname)) {
      setArticleClassName('Layout-article')
    }
    else {
      setArticleClassName('');
    }
  }, [pathname])

  return <div className={`Layout ${background ? 'Layout-background' : ''} ${articleClassName}`}>
    <Fade visible={!!background}>
      { background ? 
        <div className="Layout-background-image" style={{backgroundImage: `url(${background})`}}></div> : <></>
      }
    </Fade>
    { !isEmbed && (isMobile ?
      <NavBarMobile /> :
      <NavBar navColor={navColor}/>)
    }
    <Switch>
      <Route exact path="/">
        { isMobile ?
          <HomepageMobile /> :
          <Homepage setBackground={setBackground} setNavColor={setNavColor} />
        }
      </Route>
      <Route path="/article/:idArticle">
        <Article setBackground={setBackground} />
      </Route>
      <Route path="/embed/:idArticle">
        <Embed />
      </Route>
      <Route path="**">
        <Redirect to="/" />
      </Route>
    </Switch>
  </div>
}