import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import nextIcon from 'images/arrow.svg';
import MediaItem from 'components/media/MediaItem';

export default ({ modules }) => {
  const aspectRatio = 16/9;
  return <CarouselProvider
    className="Article-carousel"
    naturalSlideWidth={aspectRatio}
    naturalSlideHeight={1}
    totalSlides={modules.length}
    infinite={true}
    currentSlide={0}
  >
    <Slider>
      { modules.map((module, index) => 
        <Slide index={index} key={index}>
          <MediaItem data={module.media} aspectRatio={0} modules={modules}/>
        </Slide>
      )}
    </Slider>
    <div className="Article-carousel-nav">
      <ButtonBack className="Article-carousel-back" style={{backgroundImage: `url(${nextIcon})`}}></ButtonBack>
      <ButtonNext className="Article-carousel-next" style={{backgroundImage: `url(${nextIcon})`}}></ButtonNext>
    </div>
  </CarouselProvider>
}