import React, { useEffect, useRef, useState } from 'react';
import { getAbout } from 'shared/firedatabase';
import 'css/AboutSection.scss';

export default ({data, onHeightChange}) => {
  const [about, setAbout] = useState({});
  const ref = useRef();

  useEffect(() => {
    if (onHeightChange) {
      const height = ref.current.getBoundingClientRect().height;
      onHeightChange(height);
    }
    if (data) {
      setAbout(data);
    }
    else {
      getAbout().then(data => setAbout(data.about));
    }
  }, [data, onHeightChange]);

  return <div className="AboutSection" ref={ref} style={{minHeight: about.minHeight}}>
    <div className="AboutSection-wrapper">
      <div className="AboutSection-title">
        About
      </div>
      <div className="AboutSection-row">
        <div className="AboutSection-col">
          <div className="AboutSection-spacer" />
          <div className="AboutSection-content" dangerouslySetInnerHTML={{ __html: about.content }} />
        </div>
        <div className="AboutSection-col">
          <div className="AboutSection-spacer" />
          <div className="AboutSection-aside" dangerouslySetInnerHTML={{ __html: about.aside }} />
        </div>
      </div>
    </div>
  </div>
}