import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'css/NavBar.scss';
import Flyout from 'components/Flyout';
import FlyoutSearch from 'components/FlyoutSearch';
import NavBarLinks from 'components/NavBarLinks';

export default function NavBar(props) {
  const [flyout, setFlyout] = useState();
  const navColor = props.navColor || '#000000';

  function hideFlyout(type) {
    setFlyout(null);
  }

  const onChange = value => {
    setFlyout(value);
  };

  return <div className="NavBar">
    <div className="NavBar-logo">
      <Link to={'/'} >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352.08 69.66" width="200">
           <defs>
              <clipPath id="clip-path" transform="translate(0 -1.95)">
                 <rect className="cls-1" x="61.19" width="291.65" height="70.31" />
              </clipPath>
           </defs>
           <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                 <g className="cls-2" style={{fill: navColor}}>
                    <path className="cls-3" d="M351.35,14.23C345,8.18,336.5,2,322.85,2c-16.67,0-26.11,8.89-26.11,21.62,0,11.36,9.25,16.68,20.62,18.24l7.42,1c5.77.74,11.26,2,11.26,6.6,0,5.41-7.14,7.51-11.91,7.51-7,0-14.38-2.93-18.68-8.15l-12.28,7.79c6.6,7.69,14.38,13.1,29.5,13.1,18.51,0,29.41-8.89,29.41-22.54,0-11.09-7-16.22-23.82-18.6l-7.15-1c-5.5-.82-8.24-2.47-8.24-6,0-3,3-6.87,9.62-6.87,7,0,12.27,3.2,16.94,8.06Zm-98.77,1.19h10.36c5,0,12.73.09,12.73,9.44,0,8.43-5.13,10.17-12.46,10.17H252.58ZM236.37,3.51V68.19h16.21V47h13.11c11.72,0,26.11-5,26.11-21.44,0-14.84-8.07-22.08-23.37-22.08Zm-15.48,0H204.76V68.19h16.13ZM189.37,68.19V3.51H173.16V26.87H149.52V3.51H133.21V68.19h16.31V40.61h23.64V68.19ZM120,14.23C113.7,8.18,105.18,2,91.53,2,74.85,2,65.42,10.84,65.42,23.57c0,11.36,9.25,16.68,20.61,18.24l7.42,1c5.77.74,11.27,2,11.27,6.6,0,5.41-7.14,7.51-11.91,7.51-7,0-14.38-2.93-18.69-8.15L61.85,56.56c6.59,7.69,14.38,13.1,29.5,13.1,18.5,0,29.4-8.89,29.4-22.54,0-11.09-7-16.22-23.82-18.6l-7.14-1c-5.5-.82-8.25-2.47-8.25-6,0-3,3-6.87,9.62-6.87,7,0,12.28,3.2,17,8.06Z" transform="translate(0 -1.95)" />
                 </g>
                 <rect className="cls-3" style={{fill: navColor}} y="64.45" width="54.03" height="5.21" />
              </g>
           </g>
        </svg>
      </Link>
    </div>
    <NavBarLinks navColor={navColor} value={flyout} onChange={onChange} />
    {flyout === 'filter' && <Flyout onClose={hideFlyout} onMenuChange={onChange} />}
    {flyout === 'search' && <FlyoutSearch onClose={hideFlyout} onMenuChange={onChange}/>}
  </div>
}