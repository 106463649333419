import React from 'react';
import Carousel from 'components/article/Carousel';
import MediaItem from 'components/media/MediaItem';

const ArticleModule = ({module}) => {
  return <>
    { module.type === "media" &&
      <div className="ArticleModule-image">
        <MediaItem data={module.media} poster={module.image} />
      </div>
    }
    { module.type === "text" &&
      <div className="ArticleModule-text" dangerouslySetInnerHTML={{ __html: module.text }}></div>
    }
    { module.type === "html" &&
      <div className="ArticleModule-html" dangerouslySetInnerHTML={{ __html: module.html }}></div>
    }
  </>
};

const ArticleContainer = ({container, index}) => {
  const modules = container.modules || [];
  const type = container.type;
  let className = '';

  switch (type) {
    case 'left_col':
    case 'right_col':
      className = 'Article-col'; break;
    case 'full_width':
      className = 'Article-fullwidth'; break;
    case 'carousel':
      className = 'Article-carousel'; break;
    default:
      className = 'Article-wrapper'; break;
  }

  if (type === 'carousel') {
    return <Carousel {...{modules}} />
  }
  else {
    return <div {...{className}}>
      {modules.map((module, moduleIndex) => (
        <ArticleModule key={`${index}-${moduleIndex}`} {...{module}} />
      ))}
    </div>
  }
};

export default ({article}) => {
  const generateLayout = () => {
    const containers = article.moduleContainers || [];
    const layout = [];
    const layoutData = [];
    let dataIndex = 0;
 
    // Create data representation of nested modules
    containers.forEach((container, index) => {
      const type = container.type;
      const nextType = (containers[index + 1] || {}).type
      if (!layoutData[dataIndex]) {
        layoutData[dataIndex] = [];
      }
      layoutData[dataIndex].push(container);

      if (type === 'left_col' && nextType === 'right_col') {
        // If next container compliments this column container
        // don't increment add and it to the current wrapper
        return;
      }
      else {
        dataIndex++;
      }
    });

    layoutData.forEach((containers, index) => {
      const key = index;
      if (containers.length === 1) {
        layout.push(<ArticleContainer {...{key, index, container: containers[0]}} />);
      }
      else if (containers.length > 1) {
        layout.push(
          <div className="Article-wrapper" key={key}>
            <div className="Article-row">
              {containers.map((container, index) => (
                <ArticleContainer {...{key: index, index, container}} />
              ))}
            </div>
          </div>
        );
      }
    });
    return layout;
  }

  return <>
    {generateLayout()}
  </>
}