import React from 'react';
import MediaItem from 'components/media/MediaItem';
import MediaEmbed from 'components/media/MediaEmbed';
import Containers from 'components/article/Containers';

export default function(props) {

  const article = props.article || {}

  return <>
    <div className="Article-wrapper">
      { article.featuredEmbed ?
        <MediaEmbed embed={article.featuredEmbed}/> :
        article.featuredMedia ?
        <MediaItem data={article.featuredMedia} poster={article.featuredImage} /> :
        <MediaItem data={article.featuredImage} />
      }

      <div className="Article-title">{article.title}</div>
      { article.subtitle &&
        <div className="Article-subtitle">{article.subtitle}</div>
      }
      { article.attribution &&
        <div className="Article-attribution">{article.attribution}</div>
      }
      { article.content &&
        <div className="Article-content" dangerouslySetInnerHTML={{ __html: article.content }}></div>
      }
    </div>

    { article.moduleContainers &&
      <Containers {...{article}}/>
    }
    <div className="Article-spacer"></div>
  </>
}