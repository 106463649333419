import React from 'react';
import { CSSTransition } from "react-transition-group";
import 'css/Fade.css'

export default function({visible, children}) {
  return (
    <CSSTransition
      in={visible}
      timeout={500}
      classNames="Fade"
    >
      {children}
    </CSSTransition>
  )
}