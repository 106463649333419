import React, { useState } from 'react';
import { createPortal } from 'react-dom'
import Fade from 'components/Fade';
import closeImage from 'images/close-sans-white.svg';
import playIcon from 'images/play-icon-white.svg';
import listenIcon from 'images/listen-icon-white.svg';
import AudioPlayer from 'components/media/AudioPlayer';
import VideoPlayer from 'components/media/VideoPlayer';
import 'css/ImageModal.scss'
import 'css/Media.scss';

export default props => {
  const [mediaVisible, setMediaVisible] = useState(false);
  const data = props.data;
  const style = props.style;
  const poster = props.poster || {};
  const overlayImage = poster.imageUrl || data.imageUrl
  const backgroundImage = {
    backgroundImage: data.mediaType === 'audio' ? `url(${poster.imageUrl})` : 'none'
  };

  function handleClose() {
    setMediaVisible(false);
    if (window.parent && window.parent.postMessage) {
      window.parent.postMessage({name: 'close', media: data.idMedia}, '*');
    }
  }

  function expandModal() {
    setMediaVisible(true);
    if (window.parent && window.parent.postMessage) {
      window.parent.postMessage({name: 'expand', media: data.idMedia}, '*');
    }
  }

  return <>
    <div className="ImageModal-preview"
      style={{backgroundImage: `url(${overlayImage})`, ...style}}
      onClick={expandModal}
    >
      <div className="MediaModal-overlay">
        <img className="MediaModal-play-icon" src={data.mediaType === 'audio' ? listenIcon : playIcon} alt="Play button" />
      </div>
    </div>
    { createPortal(
      <Fade visible={mediaVisible}>
        <div className="ImageModal" style={backgroundImage}>
          { data.mediaType === 'video' &&
            <VideoPlayer className="MediaModal-video"
              width="80vw"
              aspectRatio={data.input.width/data.input.height}
              visible={mediaVisible}
              sources={data.outputs}
            />
          }
          { data.mediaType === 'audio' &&
            <AudioPlayer sources={data.outputs} visible={mediaVisible} />
          }
          <img className="ImageModal-close" src={closeImage} alt="Close modal" onClick={handleClose}/>
        </div>
      </Fade>
    , document.body)}
  </>
}