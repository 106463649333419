import React, { useState } from 'react';
import 'css/NavBar.scss';
import { useEffect } from 'react';

const items = [
  /*
  {
    label: 'Filter',
    value: 'filter'
  },
  */
  {
    label: 'Search',
    value: 'search'
  }
];

export default props => {
  const [menuItems, setMenuItems] = useState(items);
  const { navColor, value } = props;
  let style = {};

  const updateMenu = type => {
    const menuItems = items.map(({label, value}) => ({label, value, selected: value === type}));
    setMenuItems(menuItems);
  };

  const onChange = item => {
    if (props.onChange) {
      props.onChange(item.selected ? null : item.value);
    }
    //updateMenu(item.value);
  }

  if (navColor) {
    style.color = navColor;
  }

  useEffect(() => {
    updateMenu(value);
  }, [value])

  return <div className="NavBar-links">
    { menuItems.map(item =>
      <div key={item.value} className={item.selected ? 'NavBar-link-selected' : 'NavBar-link'} style={style} onClick={() => onChange(item)}>
        {item.label}
      </div>
    ) }
  </div>
}