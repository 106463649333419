import React, { useEffect, useState } from 'react';
import SectionPreview from 'components/SectionPreview';
import { getHomepage } from 'shared/firedatabase';


export default props => {
  const [ sections, setSections ] = useState([]);

  useEffect(() => {
    getHomepage().then(data => {
      const sections = data.sections || [];
      setSections([...sections]);
    });
  }, []);

  return <div className="Landing-mobile">
    { sections.map((item, index) =>
      <div key={index}>
        <div className="SectionLine" style={{margin: '2px 0', width: 'auto'}}/>
        <SectionPreview data={item} style={{marginTop: 0, minHeight: '200px'}}/>
      </div>
    ) }
  </div>;
};