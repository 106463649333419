import React, {} from 'react';
import ImageModal from 'components/media/ImageModal';
import MediaModal from 'components/media/MediaModal';

export default props => {
  const {mediaType} = props.data || {};

  return <>
    {
      mediaType === 'video' ?
        <MediaModal {...props} /> :
      mediaType === 'audio' ?
        <MediaModal {...props} /> :
      mediaType === 'image' ?
        <ImageModal {...props} /> : ''
    }
  </>
}