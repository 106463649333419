import React, { useEffect, useRef, useState }from 'react';
import AudioMute from 'components/media/AudioMute';
import AudioWaveform from 'components/media/AudioWaveform';
import pauseIcon from 'images/pause-white.svg';
import playIcon from 'images/play-white.svg';

export default ({visible, children, sources}) => {
  const [progress, setProgress] = useState(0);
  const [mp3Source, setMp3Source] = useState();
  const [timestamp, setTimestamp] = useState('0:00');
  const [buttonIcon, setButtonIcon] = useState(pauseIcon);
  const mediaRef = useRef();
  const mediaSources = sources || [];

  const handleTimeUpdate = ev => {
    const currentTime = ev.target.currentTime;
    setProgress(currentTime / ev.target.duration);
    const min = Math.round(currentTime / 60)
    const sec = Math.round(currentTime % 60)
    setTimestamp(`${min}:${sec < 10 ? '0' : ''}${sec}`);
  };

  const handlePlayerState = () => {
    const media = mediaRef.current;
    if (media.readyState >= 2) {
      setMp3Source(media.currentSrc);
    }
  };

  const handleChange = value => {
    mediaRef.current.currentTime = mediaRef.current.duration * value;
  };

  const togglePlay = () => {
    if (mediaRef.current.paused) {
      mediaRef.current.play();
    }
    else {
      mediaRef.current.pause();
    }
  }

  const toggleMute = () => {
    mediaRef.current.muted = !mediaRef.current.muted;
  }

  useEffect(() => {
    const media = mediaRef.current;
    if (visible) {
      media.currentTime = 0;
      media.play();
    }
    else {
      media.pause();
    }
  }, [visible]);

  return <>
    <audio
      ref={mediaRef}
      className="MediaModal-audio"
      crossOrigin={'anonymous'}
      onTimeUpdate={handleTimeUpdate}
      onLoadedData={handlePlayerState}
      onPlay={() => setButtonIcon(pauseIcon)}
      onPause={() => setButtonIcon(playIcon)}
      style={{display: 'none'}}
    >
      { mediaSources.map(item => 
        <source key={item.label} src={item.url.replace('http:', 'https:')} type={`audio/${item.label}`} crossOrigin="anonymous"/>
      )}
    </audio>
    <div className="AudioPlayer">
      <div className="AudioPlayIcon" style={{backgroundImage: `url(${buttonIcon})`}} onClick={togglePlay}/>
      <AudioWaveform src={mp3Source} progress={progress} onChange={handleChange} />
      <div className="MediaTimestamp">{timestamp}</div>
      <AudioMute mediaRef={mediaRef} visible={visible} onClick={toggleMute}/>
    </div>
  </>
}