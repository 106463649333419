import React, { useEffect, useState }from 'react';
import 'css/Flyout.scss'
import { getCategories } from 'shared/firedatabase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const loadCategories = getCategories();

export default function(props) {
  const [categories, setCategories] = useState([]);

  function selectCategory(cat) {
    const newCategories = [...categories];
    let breadcrumb = '';
    let selectedCategory;

    newCategories.forEach(category => {
      category.selected = cat.idCategory === category.idCategory && !category.selected;

      (category.subcategories || []).forEach(subcategory => {
        if (cat.idCategory === subcategory.idCategory) {
          if (!subcategory.selected) {
            selectedCategory = subcategory;
          }
          category.selected = true;
          subcategory.selected = true;
          breadcrumb = category.name + ' / ' + subcategory.name;
        }
        else {
          subcategory.selected = false;
        }
      });
    })
    setCategories(newCategories);

    props.onChange(selectedCategory, breadcrumb)
  }

  useEffect(() => {
    loadCategories.then(categories => {
      setCategories(categories.menu)
    });
  }, []);

  return <div className="FlyoutCategorySections">
    { categories.map(category =>
      <Section key={category.idCategory} {...{selectCategory, category}}></Section>
    )}
  </div>;
}

export function Section(props) {
  const [category, setCategory] = useState([]);

  useEffect(() => {
    setCategory(props.category);
  }, [props.category]);

  return <div className="FlyoutSection">
    <div className="FlyoutSection-category" onClick={() => props.selectCategory(category)}>
      { category.selected ?
        <FontAwesomeIcon icon={faMinus} /> :
        <FontAwesomeIcon icon={faPlus} />
      }
      <span className="FlyoutSection-name">{category.name}</span>
    </div>
    <div className="FlyoutSection-list" style={{display: category.selected ? 'block' : 'none'}}>
      {(category.subcategories || []).map(subcategory => (
        <div className={`FlyoutSection-item${subcategory.selected ? '-selected' : ''}`}
          key={subcategory.idCategory}
          onClick={() => props.selectCategory(subcategory)}
        >
          {subcategory.name} <sup>{subcategory.count}</sup>
        </div>
      ))}
    </div>
  </div>
}