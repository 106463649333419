import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getArticle } from "shared/firedatabase";
import MediaItem from 'components/media/MediaItem';

import 'css/Article.scss';

export default function Article(props) {
  const {idArticle} = useParams();
  const [article, setArticle] = useState({});
  const [styles, setStyles] = useState({});

  useEffect(() => {
    if (!idArticle) { return }
    getArticle({id: idArticle}).then(resp => {
      let {article} = resp;
      setArticle(article);
      setStyles({
        backgroundImage: `url(${article.featuredImage.imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh'
      });
      window.parent.postMessage({name: 'loaded', id: article.idArticle, media: article.featuredMedia.idMedia}, '*');
    });
  }, [idArticle]);

  return <MediaItem data={article.featuredMedia} poster={article.featuredImage}/>
}