import React from 'react';
import MediaItem from 'components/media/MediaItem';

export default ({ data }) => {
  return <div className="Author-container">
    <div className="Author-content Article-row">
      <div className="Author-col">
        <div className="Author-title">{ data.name }</div>
        <div className="Author-attribution">{ data.title }</div>
        <div className="Author-intro" dangerouslySetInnerHTML={{ __html: data.description }} />
        { (data.links || []).map((link, index) => (
          <div className="Author-link" key={index}>
            <a href={link.url} target="_blank"  rel="noopener noreferrer">{ link.name }</a>
          </div>
        )) }
      </div>
      { data.media &&
        <div className="Author-col">
          <MediaItem data={data.media} />
        </div>
      }
    </div>
  </div>
}