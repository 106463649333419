import { useState, useEffect, useRef } from 'react';
import { mobileWidth } from 'css/_variables.scss';


export const hasBuggyAudioCtx = () => {
  // https://bugs.webkit.org/show_bug.cgi?id=211394
  const agent = navigator.userAgent.toLowerCase()
  if (agent.includes('ipad') || agent.includes('iphone')) {
    const matches = agent.match(/os (\d+)_(\d+)/);
    const major = matches[1] || 0;
    const minor = matches[2] || 0;
    if (major === 13 && minor < 6) {
      return true;
    }
  }
  return false;
}

export const useDynamicAudioBars = ({visible, mediaRef}) => {
  const defaultBars = [...Array(16).keys()].map(_ => 0);
  const samples = [3,5,7,9,11,12]; // values to use from the frequency array
  const initialized = useRef();
  const analyser = useRef();
  const isVisible = useRef();
  const [bars, setBars] = useState(defaultBars);

  useEffect(() => {
    if (hasBuggyAudioCtx()) {
      return;
    }

    const frameLooper = () => {
      if (!isVisible.current) {
        return;
      }

      window.requestAnimationFrame(frameLooper);

      const fbc_array = new Uint8Array(analyser.current.frequencyBinCount);
      const newBars = [];
      const max = 255;

      analyser.current.getByteFrequencyData(fbc_array);
      samples.forEach(index => {
        // Convert to ratio
        const value = fbc_array[index] / max;
        newBars.push(value);
      });
      setBars(newBars)
    }

    isVisible.current = visible;
    if (visible && mediaRef.current) (async () => {
      if (initialized.current) {
        frameLooper();
      }
      else {
        initialized.current = true;
        const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        analyser.current = audioCtx.createAnalyser();
        analyser.current.fftSize = 32;
        const source = audioCtx.createMediaElementSource(mediaRef.current);
        source.connect(analyser.current);
        analyser.current.connect(audioCtx.destination);
        frameLooper()
      }
    })();
  }, [visible, mediaRef]);

  return {bars, samples}
}

export const useIsMobile = () => {
  const query = window.matchMedia(`(max-width: ${mobileWidth})`);
  const [isMobile, setIsMobile] = useState(query.matches);

  useEffect(() => {
    function handleChange(x) {
      setIsMobile(x.matches);
    }
    
    query.addListener(handleChange)
    return () => query.removeListener(handleChange);
  }, [query]);

  return isMobile;
}