import React from 'react';
import arrowIcon from 'images/arrow-white.svg';

const style = {
  container: {
    textAlign: 'center',
    height: '100px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  
  image: {
    transform: 'rotate(90deg)',
    width: '75px'
  }
};

export default props => (
  <div style={{...style.container, ...props.style}}>
    <img style={style.image} src={arrowIcon} alt="Down arrow" />
  </div>
);