import React, { useEffect, useState, useRef }from 'react';
import { Link } from 'react-router-dom';
import 'css/Flyout.scss'
import { getRecentArticles, getResources } from 'shared/firedatabase';
import closeImage from 'images/close-sans.svg';
import NavBarLinks from 'components/NavBarLinks'
import ResourceItem from 'components/article/ResourceItem'
import CategorySections from 'components/CategorySections'

export default function(props) {
  const [itemList, setItemList] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState('Most Recent');
  const currentCategory = useRef();

  const categoryChange = (category, breadcrumb) => {
    if (breadcrumb) {
      setBreadcrumb(breadcrumb)
    }
    if (category) {
      currentCategory.current = category;
      filterArticles(category);
    }
  }

  async function filterArticles(category) {
    if (category.type === 'resource') {
      const itemList = await getResources({category: category.idCategory});
      if (category === currentCategory.current) {
        setItemList(itemList.resources);
      }
    }
    else {
      const itemList = await getRecentArticles({category: category.idCategory});
      if (category === currentCategory.current) {
        setItemList(itemList.articles);
      }
    }
  }

  useEffect(() => {
    getRecentArticles().then(data => setItemList(data.articles));
  }, []);

  return <>
    <Clouds />
    <div className="Flyout">
      <div className="Flyout-left-col">
        <CategorySections onChange={categoryChange} />
      </div>
      <div className="Flyout-right-col">
        <div className="Flyout-heading">{breadcrumb}</div>
        { itemList.map(item => 
          item.idResource ?
          <ResourceResult data={item} key={item.idResource} {...props} /> :
          <Result data={item} key={item.idArticle} {...props} />
        )}
      </div>
      <img className="Flyout-close" src={closeImage} alt="close" onClick={props.onClose}/>
      <NavBarLinks value="filter" onChange={props.onMenuChange} />
    </div>
  </>;
}

export function Clouds(props) {
  return <div className="Flyout-clouds">
    <div className="Flyout-cloud-3" />
    <div className="Flyout-cloud-2" />
    <div className="Flyout-cloud-1" />
  </div>
}

export function Result(props) {
  const data = props.data || {};

  return <div className="FlyoutResult">
    <Link to={'/article/' + data.idArticle} onClick={props.onClose}>
        <img className="FlyoutResult-image" src={data.featuredImage.imageUrl} alt={data.title}/>
        <div className="FlyoutResult-title">{data.title}</div>
        <div className="FlyoutResult-subtitle">{data.subtitle}</div>
    </Link>
  </div>
}

export function ResourceResult(props) {
  const data = props.data || {};

  return <div className="FlyoutResult">
    <ResourceItem data={data} />
  </div>
}